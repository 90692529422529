import React, { useEffect, useState, useContext, useMemo }  from "react"
import axios from 'axios'
import { CSVLink } from 'react-csv'
import { Row, Col, Tab, Tabs } from 'react-bootstrap'
import { ActivityDashboardContext } from '../../../utils/context'
import { appConstants } from '../../../utils/constants.js'
import Board from "../../kanban/Board.js"
import SortableTable from "../../tables/SortableTable"
import I18n from '../../../i18n-js/locales.js'
import { SelectColumnFilter } from '../../tables/tableFilters.js'
import { BsChat } from "react-icons/bs"

export default function Activities() {
  const activityDashboardContext = useContext(ActivityDashboardContext)
  const [activities, setActivities] = useState([])
  const [kanbanBoards, setKanbanBoards] = useState([])
  const [dataUpdated, setDataUpdated] = useState(false)
  const [csvData, setCsvData] = useState([])

  const fetchActivities = async () => {
    const res = await axios.get(`${appConstants.ACTIVITIES_URL}.json`)
    setActivities(res.data)
    loadCsvData(res.data)
  }

  const fetchKanbans = async () => {
    const res = await axios.get(`${appConstants.KANBANS_URL}`)
    let newKanbanBoards = []
    res.data.map(kanbanBoard => {
      newKanbanBoards.push(setBoardData(kanbanBoard))
    })
    setKanbanBoards(newKanbanBoards)
  }

  // Fetch kanbans only after activities have been fetched
  useEffect(() => {
    if (kanbanBoards.length === 0 && activities.length > 0) {
      fetchKanbans()
    }
  }, [activities])

  useEffect(() => {
    if (activities.length === 0) {
      fetchActivities()
    }
  }, [activities])

  const tableColumns = useMemo(
    () => [
    {
      "Header": "ID",
      "accessor": "id",
      "show": false
    },
    {
      "Header": I18n.t('activerecord.attributes.activity.activity_type'),
      "accessor": "activity_type",
      "show": false
    },
    {
      "Header": I18n.t('activerecord.attributes.activity.activity_number'),
      "accessor": "activity_number",
    },
    {
      "Header": I18n.t('activerecord.attributes.activity.grantee_supplier'),
      "accessor": "awardee"
    },
    {
      "Header": I18n.t('activerecord.attributes.activity.name'),
      "accessor": "name"
    },
    {
      "Header": I18n.t('activerecord.attributes.activity.activity_status'),
      "accessor": "activity_status",
      Filter: SelectColumnFilter,
      filter: 'equals'
    },
    {
      "Header": I18n.t('activerecord.attributes.activity.admin4'),
      "accessor": "admin4",
      Filter: SelectColumnFilter,
      filter: 'equals'
    },
    {
      "Header": I18n.t('activerecord.attributes.activity.usaid_cor'),
      "accessor": "usaid_cor",
      Filter: SelectColumnFilter,
      filter: 'equals'
    },
    {
      "Header": I18n.t('activerecord.attributes.activity.funding_source'),
      "accessor": "funding_source",
      "show": false
    },
    {
      "Header": I18n.t('activerecord.attributes.activity.estimated_award'),
      "accessor": "estimated_award",
      "show": false
    },
    {
      "Header": I18n.t('activerecord.attributes.activity.summary'),
      "accessor": "summary",
      "show": false
    },
  ],
  []
)

const loadCsvData = (data) => {
  const csvHeaders = tableColumns.map(column => column.Header)
  const csvData = [
    csvHeaders,
    ...data.map(row =>
      tableColumns.map(column => row[column.accessor])
    ),
  ]
  setCsvData(csvData)
}

  // Edit link for activity list
  const editLink = (cell, row) => {
    return (
      cell["column"]["id"] === "name"
        ? <p className="trim-name"><a href="#" className="trim-name" onClick={() => activityDashboardContext.setActivityId(row["original"]["id"])}>{row["original"]["name"]}</a></p> :
      cell["column"]["id"] === "awardee" ?
        <p className="trim-name">{row["original"]["awardee"]}</p> :
      cell["column"]["id"] === "activity_number" ?
        <p className="trim-name"><a href="#" className="trim-name" onClick={() => activityDashboardContext.setActivityId(row["original"]["id"])}>{row["original"]["activity_number"]}</a></p> 
        : cell.render('Cell')
    )
  }

  // Populate kanban boards with activities
  const setBoardData = (board) => {
    const columnKeys = Object.keys(board.columns)
    let newColumns = board.columns
    let newActivities = {}
    for (let key of columnKeys) {
      newColumns[key]["activityIds"] = []
    }
    activities.map(activity => {
      if (activity.activity_status_id && newColumns[activity.activity_status_id.toString()]) {
        activity.id = activity.id.toString()
        newActivities[activity.id] = activity
        newColumns[activity.activity_status_id]["activityIds"].push(activity.id)
      }
    })
 
    const newBoard = {
      ...board,
      activities: newActivities,
      columns: newColumns,
    }
    return newBoard
  }

  // Persist status change to database
  const persistStatus = (activity_id, status_id) => {
    console.log("Persisting status")
    axios.defaults.headers.common["X-CSRF-TOKEN"] = activityDashboardContext.authenticityToken;
    axios.put(`${appConstants.UPDATE_ACTIVITY_STATUS_URL}/${activity_id}`, {"activity_status_id": status_id} )
      .then(res => {
        setDataUpdated(true)
      })
      .catch(error => {
        console.log(error)
      })
  }

  // Activity content for kanban boards
  const activityContent = (activity) => {
    return (
      <div className="activity-content">
        <a href="#" onClick={() => activityDashboardContext.setActivityId(activity.id)}>{activity.id}. {activity.name}</a> <br/>
        <span className="small">
          <b>{I18n.t('activerecord.attributes.activity.activity_number')}:</b> {activity.activity_number} <br/>
          <b>{activity.activity_type === "Grant" ? I18n.t('activerecord.attributes.activity.awardee') : I18n.t('activerecord.attributes.activity.supplier')}:</b> {activity.awardee} <br/>
          <b>{I18n.t('labels.last_updated')}:</b> {activity.last_updated} <br/>
          {activity.has_updated_by_note ? <BsChat /> : ""} <b>{I18n.t('labels.updated_by')}:</b> {activity.updated_by} <br/>
          { activity.activity_type === "Grant" ? (
            <>
            {activity.has_cor_note ? <BsChat /> : ""} <b>{I18n.t('activerecord.attributes.activity.usaid_cor')}:</b> {activity.usaid_cor} <br/>
            {activity.has_co_note ? <BsChat /> : ""} <b>{I18n.t('activerecord.attributes.activity.usaid_co')}:</b> {activity.usaid_co} <br/>
            </>
          ) : ""
          }
        </span>
      </div>
    )
  }

  const renderBoard = (kanban) => {
    return (
      <Tab eventKey={kanban.id} title={kanban.name} key={kanban.id}>
        <Board board={kanban} activityContent={activityContent} persistStatus={persistStatus} key={kanban.id} />
      </Tab>
    )
  }

  return activities.length > 0  ? (
    <Row>
      <Col md={12}>
        <Tabs
          id="activities-tabs"
          activeKey={activityDashboardContext.activityTab}
          onSelect={(k) => {
            activityDashboardContext.setActivityTab(k)
            if (dataUpdated) {
              setActivities([])
              setKanbanBoards([])
              setDataUpdated(false)
            }
          }}
          className="mb-3"
        >
          <Tab eventKey="list" title={I18n.t('activerecord.attributes.activity.list')}>
            <Row>
              <Col className="text-end">
                <CSVLink filename="hcsp-grants.csv" data={csvData}>
                  Export to CSV
                </CSVLink>
              </Col>
            </Row>
            <SortableTable
              data={activities}
              columns={tableColumns}
              useRenderFunction={true}
              cellContent={editLink}
            />  
          </Tab>
          { kanbanBoards.map(kanban =>
              renderBoard(kanban)
            )
          }
        </Tabs>
      </Col>
    </Row>
  ) : ""
}